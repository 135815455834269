























































































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartRingComponent, ReportChartHistogramComponent } from '@/mixins/report-chart-base';
import FireIndexService from './service/fire-index';
import { ParkingGateType } from '@/model/enum';
import { percentFormat } from '@/filter';
import { FireAlarmTrendQueryModel } from './model/fire-alarm-trend-model';

@Component
export default class FireIndexComponent extends Mixins(ReportChartRingComponent, ReportChartHistogramComponent) {
    ParkingGateType = ParkingGateType;
    percentFormat = percentFormat;
    headerCardList: Array<any> = [
        { title: '正在告警', count: null, unit: '条', loading: true, icon: '/img/alarm.png' },
        { title: '今天未处理告警', count: null, unit: '条', loading: true, icon: '/img/alarm-unhandle.png' },
        { title: '今天告警总数', count: null, unit: '条', loading: true, icon: '/img/alarm-total.png' },
        { title: '异常设备', count: null, unit: '台', loading: true, icon: '/img/fault.png' },
        { title: '设备总数', count: null, unit: '台', loading: true, icon: '/img/device.png' }
    ];
    alarmLocationData: any = {};
    alarmLocationDataLoading: boolean = true;

    alarmStatusCountData: any = null;
    alarmStatusCountDataLoading: boolean = true;

    last7DayAlarmData: any = {};
    last7DayAlarmDataLoading: boolean = true;

    alarmLevelCountData: any = null;
    alarmLevelCountDataLoading: boolean = true;

    last7DayTop5AlarmData: any = null;
    last7DayTop5AlarmDataLoading: boolean = true;

    facilityStatusData: any = null;
    facilityStatusDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        FireIndexService.getAlarmCount().then(res => {
            this.headerCardList[0].count = res.activeCount;
            this.headerCardList[1].count = res.unsolvedCount;
            this.headerCardList[2].count = res.totalCount;
            this.alarmStatusCountData = {
                columns: ['name', 'value'],
                rows: [
                    { name: '已处理', value: res.solvedCount },
                    { name: '未处理', value: res.unsolvedCount }
                ]
            };
        }).finally(() => {
            this.headerCardList[0].loading = false;
            this.headerCardList[1].loading = false;
            this.headerCardList[2].loading = false;
            this.alarmStatusCountDataLoading = false;
        });
        FireIndexService.getFacilityCount().then(res => {
            this.headerCardList[3].count = res.faultCount;
            this.headerCardList[4].count = res.totalCount;
            this.facilityStatusData = {
                columns: ['name', 'value'],
                rows: [
                    { name: '异常', value: res.faultCount },
                    { name: '正常', value: res.totalCount - res.faultCount }
                ]
            };
        }).finally(() => {
            this.headerCardList[3].loading = false;
            this.headerCardList[4].loading = false;
            this.facilityStatusDataLoading = false;
        });

        FireIndexService.getLocationAlarmCount().then(res => {
            this.alarmLocationData = res;
        }).finally(() => this.alarmLocationDataLoading = false);

        FireIndexService.getLast7DayAlarmCount(new FireAlarmTrendQueryModel().toService()).then(res => {
            this.last7DayAlarmData = res;
        }).finally(() => this.last7DayAlarmDataLoading = false);

        FireIndexService.getAlarmGradeCount().then(res => {
            this.alarmLevelCountData = res;
        }).finally(() => this.alarmLevelCountDataLoading = false);

        FireIndexService.getLast7DayTop5AlarmCount({ limit: 5 }).then(res => {
            this.last7DayTop5AlarmData = res;
        }).finally(() => this.last7DayTop5AlarmDataLoading = false);
    }

    get ParkingGateRankYAxis() {
        return {
            type: 'category',
            data: _.map(this.last7DayTop5AlarmData.rows, item => item.name),
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            splitLine: {
                show: false
            }
        };
    }
}
